<template>
  <div>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      loader="dots"
    ></Loading>
    <b-modal
      class="p-2"
      id="export-modal"
      hide-header-close
      hide-header
      hide-footer
    >
      <b-row align-h="end">
        <button
          class="close_modal text-light border-none p-0 mx-2 mb-1"
          @click="$bvModal.hide('export-modal')"
        >
          <b-icon-x scale="1.5" />
        </button>
      </b-row>
      <p class="text-center pb-3 btn-auto">{{ $t("asset.choose_ex") }}</p>
      <b-row align-h="center">
        <!-- <button class="px-2 email-me" @click="onExport(true)">
          {{ $t("btn.send_mail") }}
        </button> -->

        <button class="px-2 download-file" @click="onExport(false)">
          {{ $t("btn.dl_file") }}
        </button>
      </b-row>
    </b-modal>
    <div class="log-section">
      <div class="main">
        <p class="company-name">{{ companyName }}</p>
        <button
          v-if="!isNativeApp"
          class="button-load p-sm-1 px-1 my-3"
          style="min-width: max-content"
          @click="$bvModal.show('export-modal')"
        >
          <b-icon-upload
            class="p-1"
            font-scale="2"
            style="color: #007afe"
          ></b-icon-upload
          >{{ $t("depreciation.export_file") }}
        </button>
        <div class="mt-3">
          <LandLogTable
            :dataTable="assetLog"
            :otherColumn="otherColumn"
            :page="currentPage"
            :perPage="perPage"
          >
          </LandLogTable>
          <div class="d-flex justify-content-center mt-3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import NavMenu from "@/components/Footer.vue";
import Swal from "sweetalert2";
import XLSX from "xlsx-js-style"; // import xlsx
import Loading from "vue-loading-overlay";
import moment from "moment";
import LandLogTable from "../../components/Table/Depreciation/LandLogTable.vue";
import baseUrl from "../../util/backend";
import axios from "axios";
import { authHeader } from "../../store/actions";
import { mapGetters } from "vuex";

export default {
  components: { Loading, LandLogTable },
  data() {
    return {
      isLoading: false,
      columnPermission: null,
      assetLog: [],
      otherColumn: [],
      assetId: "",
      perPage: 100,
      currentPage: 1,
      totalRows: 1,
    };
  },
  computed: {
    ...mapGetters({
      isNativeApp: "isNativeApp",
      getUserDetail: "getUserDetail",
      companyName: "companyName",
      branchName: "branchName",
      departmentName: "departmentName",
      isRoleLower: "isRoleLower",
    }),
  },
  watch: {
    currentPage() {
      this.getLog();
    },
  },
  methods: {
    // goToAssetLog() {
    //   this.$router.push(`/assetlog/${this.$route.params.id_asset}`);
    // },
    async onExport(isMail) {
      this.isLoading = true;
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      try {
        const res = await this.$store.dispatch("ExportLandLog", {
          id_company: id_company,
          id_asset: this.$route.params.id_asset,
          filename: `${this.$t("depreciation.download_land_Log")}_${
            this.companyName
          }_${moment().format("DD-MM-YYYY_HH-mm")}.xlsx`,
        });
        this.isLoading = false;
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },
    async getLog(type, amountPage) {
      this.isLoading = true;
      let perPage = amountPage || this.perPage;
      let link = "";
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      try {
        link = `${baseUrl()}asset/getlandlog/${id_company}?id_asset=${
          this.$route.params.id_asset
        }&limit=${perPage}&page=${this.currentPage}`;

        const res = await axios.get(link, authHeader());
        let logArr = res.data.data;
        if (type !== "export") {
          this.assetId = res.data.data[0].id_fixed_asset;
          this.totalRows = res.data.count;
          this.otherColumn = res.data.other_column;
          this.assetLog = logArr;
        } else {
          this.isLoading = false;
          return logArr;
        }
      } catch (err) {
        if (err.response) {
          alert(err.response.message);
        } else {
          alert(err);
        }
      }
      this.isLoading = false;
    },
  },
  created() {
    this.getLog();
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}

.topic {
  padding-top: 2rem;
}

.count {
  font-size: 14px;
  color: #888888;
}

.more-log {
  font-size: 14px;
  color: #007afe;
  font-weight: 700;
  cursor: pointer;
}

.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
}

@media only screen and (max-width: 412px) {
  .back-icon {
    padding: 10px;
    cursor: pointer;
  }
}
.header-text {
  height: 173px;
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: auto;

  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 14px;
  }
}

.log-section {
  text-align: left;
  margin: 20px auto 0 auto;
  padding: 0.4em;
}

.date-filter {
  input[type="date"] {
    border-radius: 10px;
    box-sizing: border-box;
    outline: 0;
    padding: 0.75rem;
    position: relative;
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
}

.btn-login {
  // margin-left: 2rem;
  // padding: 0.55rem 2.75rem 0.55rem 2.75rem;
  background: transparent linear-gradient(180deg, #007bff 0%, #003e80 100%) 0%
    0% no-repeat padding-box;
}
.btn-regist {
  width: 160px;
  height: 45px;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 10px;
  color: $color-active-blue;
  box-shadow: $shadow-default;
  border: solid 2px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(180deg, #007afe 0%, #013f81 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
}

.button-load {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: transparent;
  font-size: 14px;
  margin: 0.1rem;
  width: fit-content;
  height: 45px;
  background: transparent;
  color: #007afe;
  border: solid;
  border-radius: 11px;
  padding: 5px;
  color: #007afe;
  border: 2px solid #007afe;
}

.download-file {
  padding: 0 10px 0 10px;
  color: white;
  border-radius: 8px;
  border: none;
  width: 130px;
  height: 34px;
  font-size: 14px;
  background: transparent -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#007afe),
      to(#013f81)
    ) 0% 0% no-repeat padding-box;
}
.email-me {
  background: transparent;
  color: #007afe;
  border: solid 2px;
  border-radius: 8px;
  width: 130px;
  height: 34px;
  font-size: 14px;
  margin-right: 10px;
}

@media only screen and (min-width: 1440px) {
  .main {
    overflow-x: scroll;
    padding-left: 2rem !important;
    padding-right: 1rem !important;
  }
}

.company-name {
  font-size: 18px;
}
</style>
